import React from "react";
import { Link } from "gatsby";
//import img from '../../static/img/chemex.jpg'

class Footer extends React.Component {
  render() {
    return (
      <footer className="yarn-footer">
        {/* <div className="columns site-footer-top is-gapless">
        <div className="column site-footer-top-left">
          <div className="site-footer-top-left-bg red-overlay">
            <img src={img} alt="Kaldi" />
          </div>
          <div className="site-footer-top-left-content">
            Donate
          </div>
          One
        </div>
        <div className="column site-footer-top-right">
          Two
        </div>
      </div> */}
        <div id="site-footer-bottom">
          <div className="columns level">
            <div className="column is-3 site-footer-bottom-left">
              {/* 
              <Link to="/products">Privacy Policy</Link>
              */}
            </div>
            <div className="column is-6">
              <div className="content has-text-centered level-item">
                © RedYarn 2018. All rights reserved.
              </div>
            </div>
            <div className="column is-3 level-item">
              <a href="/admin/" target="_blank" className="button is-primary ">
                admin
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
