import React from "react";
import { Link } from "gatsby";
import logo from "../img/YarnLogoWhite.png";

class Navbar extends React.Component {
  state = {
    isActive: false
  };

  toggleNav = () => {
    this.setState(prevState => ({
      isActive: !prevState.isActive
    }));
  };

  render() {
    return (
      <nav className="navbar is-transparent is-primary">
        <div className="container">
          <div className="navbar-brand">
            <Link to="/" className="navbar-item">
              <figure className="image">
                <img src={logo} alt="The Red Yarn" />
              </figure>
            </Link>
            <div
              className="navbar-burger burger"
              data-target="navbarExampleTransparentExample"
              onClick={this.toggleNav}
            >
              <span />
              <span />
              <span />
            </div>
          </div>
          <div
            id="navbarTransparent"
            className={
              this.state.isActive ? "navbar-menu is-active" : "navbar-menu"
            }
          >
            <div className="navbar-start">
              <Link className="navbar-item" to="/">
                HOME
              </Link>
              <Link className="navbar-item" to="/p/achtergrond">
                Achtergrond
              </Link>
              <Link className="navbar-item" to="/p/over_ons">
                Over ons
              </Link>
              {/* <Link className="navbar-item" to="/blog">
                Blogs
              </Link> */}
              {/* <a
                href="http://tcf.org.pk/"
                target="_blank"
                className="navbar-item"
              >
                TCF
              </a>
              <a
                href="https://www.tcf.org.pk/resources/"
                target="_blank"
                className="navbar-item"
              >
                Reports
              </a> */}
            </div>
            <div className="navbar-end">
              <Link
                to="/p/contact"
                className="navbar-item"
              >
                CONTACT
                </Link>
            </div>
            {/* <div className="navbar-end">
              <div className="navbar-item">
                <Link
                  to="/donate"
                  className="button is-primary is-inverted is-outlined"
                >
                  Donate Now
                </Link>
              </div>
            </div> */}
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
