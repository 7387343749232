import React from "react";
import Helmet from "react-helmet";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "./layout.sass";

import favicon from "../img/favicon.png";

const TemplateWrapper = ({ children }) => (
  <div className="layout">
    <Navbar />
    <main>
      <Helmet
        title="Home | The Red Yarn"
        link={[{ rel: "shortcut icon", type: "image/png", href: `${favicon}` }]}
      />
      {children}
    </main>
    <Footer />
  </div>
);

export default TemplateWrapper;
